.table {
    display: none;

    @media(min-width: 1024px) {
        display: block;

        &__table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%; }

        thead {
            text-align: left;
            background: #d7e1e6;
            border: 1px solid #d7e1e6;

            th {
                padding: 15px 20px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: 'LindeDaxMed';
                text-align: left; } }

        tbody {
            border: 1px solid #cccccc;

            td {
                font-size: 14px;
                line-height: 21px;
                padding: 15px 20px;
                color: #586060; }

            tr:nth-child(even) {
                background-color: #f7f7f7; } }

        &--fixed-7 {
            th {
                width: calc(100% / 7); } }

        &--fixed-8 {
            th {
                width: calc(100% / 8); } } } }
