.tooltip {
    border: 1px solid #586060;
    background: linear-gradient(180deg, #ffffff 0%, #eeeeee 100%);
    box-sizing: border-box;
    position: absolute;
    bottom: 25px;
    width: 270px;
    padding: 15px 20px;
    left: -95px;

    @media(min-width: 768px) {
        right: auto;
        left: auto; }
    @media(min-width: 1024px) {
        right: 0; }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 100%;
        height: 100%;
        background: #cccccc;
        z-index: -1; }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-family: 'Arial';
        color: #e50014; }

    &__title {
        font-size: 14px;
        line-height: 21px;
        color: #586060;
        font-family: 'LindeDaxMed';
        margin-top: 0; }

    &__content {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 20px;
        color: #586060;
        font-family: 'LindeDax';

        ul {
            list-style: none;
            margin: 0;
            padding: 0; } }

    &__table-title {
        font-family: 'LindeDaxMed'; } }
