.tile {
    border: 2px solid #586060;
    box-sizing: border-box;
    cursor: pointer;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 20px 20px;
    width: 285px;
    max-width: calc(100% - 40px);
    font-size: 22px;
    line-height: 28px;

    &__icon {
        align-self: center;
        margin-top: 20px;

        &-hover {
            align-self: center;
            margin-top: 20px;
            display: none; } }

    &:hover {
        border-color: #e50014;
        background: #e50014;
        color: #fff;

        .tile__icon {
            display: none;

            &-hover {
                display: block; } } }

    @media(min-width: 768px) {
        margin: 0;

        & + .tile {
            margin-left: 20px; } } }
