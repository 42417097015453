@font-face {
    font-display: swap;
    font-family: 'LindeDax';
    font-style: normal;
    src: local('LindeDax'), url('./fonts/LindeDaxRegWeb.woff') format('woff'); }

@font-face {
    font-display: swap;
    font-family: 'LindeDaxMed';
    font-style: normal;
    src: local('LindeDax'), url('./fonts/LindeDaxMedWeb.woff') format('woff'); }

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    padding: 0;
    margin: 0;
    font-family: 'LindeDax';
    font-size: 1rem;
    font-size: 16px; }

.section {
    padding: 30px 0; }

.u-pos-rel {
    position: relative; }

.u-flex {
    display: flex; }

.u-flex-wrap {
    flex-wrap: wrap; }

.u-justify-center {
    justify-content: center; }

.u-justify-space-between {
    justify-content: space-between; }

.u-align-center {
    align-items: center; }

.u-text-center {
    text-align: center; }

.u-hide {
    display: none;

    @media(max-width: 767px) {
        &\@medium-min {
            display: none; } }

    @media(max-width: 1024px) {
        &\@large-min {
            display: none; } } }

.u-mt0 {
    margin-top: 0; }

.u-mt {
    margin-top: 20px; }

.u-mt-double {
    margin-top: 40px; }

.u-mt-90 {
    @media(min-width: 767px) {
        &\@medium-min {
            margin-top: 90px; } } }

.u-mb0 {
    margin-bottom: 0; }

.u-mb {
    margin-bottom: 20px; }

.u-mb-double {
    margin-bottom: 40px; }

.u-lh-16 {
    line-height: 1.5; }

.u-size-12 {
    font-size: 12px; }

.u-size-14 {
    font-size: 14px; }

.u-size-16 {
    font-size: 16px; }

.u-color-corduroy {
    color: #586060; }

.u-brand {
    color: #e50014; }

.u-text-center {
    text-align: center; }

.u-bt {
    border-top: 1px solid #cccccc; }

.u-arrow-right {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        border-top: 2px solid #e50014;
        border-right: 2px solid #e50014;
        transform: rotate(45deg);
        margin-left: 10px; } }

.u-arrow-left {
    position: absolute;
    left: 10px;
    top: 12px;
    transform: rotate(180deg);
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        border-top: 2px solid #e50014;
        border-right: 2px solid #e50014;
        transform: rotate(45deg);
        margin-left: 10px; } }

.u-arrow-grey {
    cursor: default;

    &:before,
    &:after {
        border-color: #cccccc; } }

.u-w100 {
    width: 100%; }

.heading--giga {
    font-size: 48px;
    line-height: 56px;
    color: #333333;
    margin-top: 0;
    margin-bottom: 30px; }

h1 {
    font-size: 28px;
    line-height: 34px;
    color: #343436;
    font-family: 'LindeDax';
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 1.5em; }

h2 {
    color: #586060;
    font-size: 22px;
    line-height: 28px;
    font-family: 'LindeDax';
    font-weight: normal;
    margin-bottom: 0;
    margin-bottom: .25em; }

hr {
    height: 0;
    box-sizing: content-box;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    width: 1200px;
    max-width: 100%; }

.tag-title {
    color: #333333;
    font-size: 16px;
    font-family: 'LindeDaxMed';
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    @media(min-width: 768px) {
        margin-right: 10px;
        display: inline;
        width: auto; } }

.container {
    width: 1200px;
    max-width: 100%;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;

    &--small {
        max-width: 100%;
        width: 997px; } }

.modal {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: auto;

    &__close {
        background: transparent;
        border: 0;
        position: absolute;
        right: 14px;
        top: 14px;
        cursor: pointer;
        width: 20px;
        height: 20px;

        &:hover {
            opacity: 1; }

        &:before,
        &:after {
            position: absolute;
            left: 8px;
            top: 0;
            content: ' ';
            height: 18px;
            width: 2px;
            background-color: #e50014; }

        &:before {
            transform: rotate(45deg); }

        &:after {
            transform: rotate(-45deg); } } }

.tooltip-icon {
    height: 14px;
    width: 14px;
    background: #e50014;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
    background-image: url('./images/i.svg');
    background-repeat: no-repeat;
    background-position: center;

    @media(min-width: 768px) {
        position: absolute;
        top: 50%;
        right: 5px;
        margin-left: 0;
        transform: translateY(-50%); }

    @media(min-width: 1024px) {
        top: 17px;
        transform: none; } }
