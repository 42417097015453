.box {
    background: #f7f7f7;
    box-sizing: border-box;
    border-bottom: 1px solid #cccccc;
    padding: 20px;
    width: 100%;

    &__inner {
        max-width: 824px;
        width: 100%;
        margin: auto; }

    &__title {
        text-align: center;
        color: #343436;
        font-size: 22px;
        line-height: 28px; }

    @media(min-width: 768px) {
        padding: 40px 20px; } }
