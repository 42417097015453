.dev-tools {
    background: #000;
    color: #fff;
    width: 100%;
    padding: 20px;
    height: 50px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;

    &__title {
        margin-right: 20px; }

    &__options {
        margin-left: 20px; } }
