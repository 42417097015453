.panel {
    box-sizing: border-box;
    margin: 0 auto 40px;
    width: 997px;
    max-width: 100%;

    &__header {
        background: #f7f7f7;
        padding: 20px;
        text-align: center;
        font-size: 22px;
        line-height: 24px;
        color: #333333; }

    &__content {
        padding: 20px;
        border: 1px solid #cccccc;
        border-top: 0; }

    &__inner {
        width: 100%;
        max-width: 615px;
        margin: 0 auto 20px;

        display: flex;
        flex-wrap: wrap; }

    &__actions {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            &:nth-child(1) {
                margin-top: 20px;
                order: 2; }

            &:nth-child(2) {
                order: 1; }

            &:nth-child(3) {
                order: 3; } } }

    &--wide {
        width: 1200px; }


    @media(min-width: 768px) {
        margin-bottom: 180px;
        &--product {
            margin-bottom: 40px; }

        &__header {
            padding: 40px;
            font-size: 28px;
            line-height: 34px; }

        &__content {
            padding: 40px; }

        &__inner {
            margin-bottom: 40px;
            justify-content: center; }

        &__actions {
            justify-content: space-between;
            flex-direction: row;

            button {
                margin: 0;

                &:nth-child(1) {
                    order: 1; }

                &:nth-child(2) {
                    order: 2; }

                &:nth-child(3) {
                    order: 3; } } } } }
