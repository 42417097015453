.radio {
    margin: 0 auto 15px;
    width: 285px;
    max-width: 100%;

    &__label {
        font-family: 'Trebuchet MS', helvetica, arial, sans-serif;
        font-size: 15px;
        display: block;
        padding: 15px 20px 15px 40px;
        border: 2px solid #333333;
        cursor: pointer;
        margin: auto;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        box-sizing: border-box;
        text-align: center;
        height: 100%; }

    &__input {
        -webkit-appearance: none;
        cursor: pointer;
        margin: 0;
        border: 1px solid #cccccc;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;

        position: absolute;
        left: 12px;
        outline: none; }

    &--checked {
        .radio__label {
            background: #e50014;
            color: #fff;
            border-color: #e50014; }

        .radio__input {
            &:before {
                content: '';
                display: block;
                height: 8px;
                width: 8px;
                background: #e50014;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 5px; }

            &::-ms-check {
                border-color: #e50014;
                color: #e50014; } } }

    @media(min-width: 768px) {
        margin: 0 10px 15px;
        max-width: 250px;

        &:hover {
            .radio__label {
                border-color: #db1e34; } } } }
