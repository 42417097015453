.table-mobile {
    margin-bottom: 20px;

    &__table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%; }

    thead {
        border: 1px solid #cccccc;
        border-bottom: 0;

        th {
            text-align: left;
            padding: 10px 20px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            // display: none
            text-align: center;
            position: relative;

            &:first-child {
                background: #d7e1e6;
                display: table-cell;
                text-align: left; }

            &:nth-child(2) {
 } } }                // display: table-cell

    tbody {
        border: 1px solid #cccccc;
        border-top: 0;

        td {
            // display: none
            padding: 10px 20px;
            width: 50%;
            font-family: 'Trebuchet MS', helvetica, arial, sans-serif;
            font-size: 13px;
            color: #586060;

            &:first-child {
                // display: table-cell
                background: #d7e1e6;
                font-size: 14px;
                color: #333333;
                font-family: 'LindeDaxMed'; }

            // &:nth-child(2)
            //     display: table-cell
            // font-size: 14px
            // line-height: 21px
            // padding: 15px 20px
 }            // color: #586060

        tr:nth-child(even) {
            background-color: #f7f7f7; } }

    @media(min-width: 768px) {
        width: calc(50% - 20px);
        margin-bottom: 0; }

    @media(min-width: 1024px) {
        display: none; } }
