.cta {
    border: 1px solid #CCCCCC;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    padding: 20px;

    &__title {
        color: #E50014;
        font-size: 28px;
        line-height: 34px;
        margin-top: 0; }

    &__copy {
        color: #586060;
        font-size: 14px;
        line-height: 21px; }

    &__img {
        max-width: 100%;
        width: 250px;
        margin-bottom: 20px; }

    &__actions {
        width: 250px;
        flex-shrink: 0;
        max-width: 100%;
        margin: auto; }


    @media(min-width: 768px) {
        display: flex;
        padding: 55px 60px;
        text-align: left;

        &__group {
            margin-left: 40px; }

        &__title {
            font-size: 36px;
            line-height: 39px; }

        &__copy {
            font-size: 16px;
            line-height: 24px; }

        &__img {
            align-self: center;
            flex-shrink: 0; }

        @media(min-width: 1024px) {
            &__group {
                display: flex;
                margin: 0;
                flex-shrink: 0; }

            &__img {
                margin: 0 40px 0; } } } }
