.tag {
    font-family: 'Trebuchet MS', helvetica, arial, sans-serif;
    background: #d7e1e6;
    display: inline-block;
    padding: 8px 12px;
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 10px;

    & + .tag {
        margin-left: 10px; }

    // @media(min-width: 768px)
 }    //     margin-right: 10px
