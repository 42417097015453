.product {
    display: flex;
    flex-wrap: wrap;

    &__image {
        flex-shrink: 0;
        align-self: center;
        margin: 0 auto 20px; }

    &__info {
        background: #d7e1e6;
        padding: 20px;
        margin-bottom: 20px;
        max-width: 450px;
        box-sizing: border-box;
        width: 100%;

        &-title {
            font-size: 14px;
            color: #333333;
            font-family: 'LindeDaxMed';
            margin-top: 0; }

        &-copy {
            font-size: 12px;
            line-height: 16px;
            color: #333333; }

        dl {
            display: flex;
            flex-wrap: wrap;
            margin: 0; }

        dt {
            width: calc(40% - 20px);
            margin-right: 20px;
            margin-bottom: 10px; }

        dd {
            width: 60%;
            margin: 0;
            color: rgba(#333333, .8); } }

    &__copy {
        color: #586060;
        font-size: 14px;
        line-height: 21px; }

    &__heading {
        color: #E50014;
        font-size: 22px;
        line-height: 28px; }

    @media(min-width: 480px) {
        &__info {
            margin-right: 20px; }

        &__content {
            margin-bottom: 20px; } }

    @media(min-width: 800px) {
        flex-wrap: nowrap;
        margin: 0;

        &__image {
            margin-right: 30px;
            margin-bottom: 0; }

        &__content {
            margin-right: 55px;
            margin-bottom: 0; }

        &__heading {
            font-size: 24px;
            margin-bottom: 15px;
            margin-top: 0; }

        &__copy {
            font-size: 16px;
            line-height: 24px; }

        &__info {
            padding: 30px;
            margin-bottom: 0;
            margin-right: 0;
            width: auto; } }

    @media(min-width: 1024px) {
        &__image {
            margin-right: 74px; }

        &__info {
            flex-shrink: 0; } } }
