.button {
    background: #E50014;
    border: 0;
    height: 46px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    width: 100%;
    font-family: 'LindeDax';
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(min-width: 479px) {
        max-width: 250px; }

    &:hover {
        background: darken(#e50014, 5); }

    &--secondary {
        background: #fff;
        border: 1px solid #e50014;
        color: #333333;

        &:hover {
            background: #e50014;
            color: #fff; } }

    &--link {
        background: transparent;
        font-size: 14px;
        color: #333333;
        height: auto;
        width: auto;
        max-width: none;

        &:hover {
            background: transparent;
            color: darken(#333333, 5); } }

    &--warning {
        color: #e50014; }

    &--has-arrow-left {
        display: inline-flex;
        align-items: baseline;

        &:before {
            content: '';
            display: block;
            height: 10px;
            width: 10px;
            border-top: 2px solid #e50014;
            border-left: 2px solid #e50014;
            transform: rotate(-45deg);
            margin-right: 10px; } }

    &--has-arrow-right {
        display: inline-flex;
        align-items: baseline;

        &:after {
            content: '';
            display: block;
            height: 10px;
            width: 10px;
            border-top: 2px solid #e50014;
            border-right: 2px solid #e50014;
            transform: rotate(45deg);
            margin-left: 10px; } }

    + .button {
        margin-top: 10px; }

    + .button--link {
        margin-top: 20px; }

    &:disabled,
    &[disabled] {
        background: #cccccc; }

    &--results {
        margin-bottom: 20px; }

    @media(min-width: 767px) {
        &--results {
            position: relative;
            top: 38px;
            margin-bottom: 0; } } }
